import React from "react";
import ModalComponent from "./Modal";
import SuccessIcon from "../../assets/icons/SuccessIcon.png";
import { Button } from "../Button/Button";
const ConfirmShowBooking = ({
  loading,
  opened,
  setOpened,
  handelSubmitShow,
  paidAmount,
  selectedDay,
}) => {
  return (
    <ModalComponent opened={opened} setOpened={setOpened}>
      <div className="flex justify-center flex-col items-center gap-[25px]">
        <img className="w-[80px]" src={SuccessIcon} alt="delete" />
        <div className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
          Are you sure this payment is complete?
        </div>
        <p>
          Marking this booking as “Show” will invoice the amount paid for day:{" "}
          <b>{selectedDay && new Date(selectedDay).toLocaleDateString()}</b> , and you will not be
          able to invoice further payments on this booking. If confirmed, <b>{paidAmount} EGP</b>{" "}
          will be invoiced on <b>{new Date().toLocaleDateString()}</b>.
        </p>

        <div className="flex  gap-[40px]">
          <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
            Cancel
          </Button>
          <Button loading={loading} onClick={handelSubmitShow} secondary>
            Confirm
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ConfirmShowBooking;
