import React, { useEffect, useContext } from "react";
import { useState } from "react";
import logo from "../../assets/Gray & Turquoise Logo-01 (2).png";
import { AppShell, Navbar, Header, MediaQuery, Burger, useMantineTheme } from "@mantine/core";
import { useSignOut } from "react-auth-kit";
import { useAuthUser } from "react-auth-kit";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Gauge,
  DeviceDesktopAnalytics,
  User,
  CreditCard,
  Package,
  Settings,
  Video,
  Coin
} from "tabler-icons-react";
import useFetchData from "../../hooks/FetchData";
import { Select } from "@mantine/core";
import { IconMapPin } from "@tabler/icons";
import ProfileIcon from "../../assets/icons/profile.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SelectedLocationContext } from "../../Context/LocationProvider";

export const Layout = ({ children }) => {
  const { selectedLocation, setSelectedLocation } = useContext(SelectedLocationContext);
  const [activeTap, setActiveTap] = useState(Number(localStorage.getItem("tap")));
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const LogOut = useSignOut();
  const navigator = useNavigate();
  const auth = useAuthUser();
  const location = useLocation();
  const { response: allLocations, loading: loadingLocations } = useFetchData("/location/me");

  const SignOut = () => {
    LogOut();
    localStorage.removeItem("tap");
    localStorage.removeItem("selectedLocation");
    localStorage.removeItem("vas-cart");
    navigator("/login");
  };

  let links = [
    {
      index: 0,
      link: "/",
      icon: Gauge,
      label: "Dashboard",
      active: true,
      allowed: ["Super Admin", "Area Manager", "Branch Manager", "Land Lord"],
    },
    {
      index: 1,
      link: "/operations",
      icon: DeviceDesktopAnalytics,
      label: "Experience",
      active: false,
      allowed: ["Super Admin", "Area Manager", "Branch Manager", "Experience Officer", "Land Lord"],
    },
    {
      index: 2,
      link: "/finance",
      icon: CreditCard,
      label: "Finance",
      active: false,
      allowed: ["Super Admin", "Area Manager", "Branch Manager", "Experience Officer", "Land Lord"],
    },
    {
      index: 3,
      link: "/community",
      icon: User,
      label: "Community",
      active: false,
      allowed: ["Super Admin", "Area Manager", "Branch Manager", "Experience Officer", "Land Lord"],
    },
    {
      index: 4,
      link: "/inventory",
      icon: Package,
      label: "Inventory",
      active: false,
      allowed: ["Super Admin", "Area Manager", "Branch Manager", "Land Lord"],
    },
    {
      index: 5,
      link: "/settings",
      icon: Settings,
      label: "Settings",
      active: false,
      allowed: ["Super Admin", "Area Manager", "Branch Manager"],
    },
    {
      index: 6,
      link: "/tutorials",
      icon: Video,
      label: "Tutorials",
      active: false,
      allowed: ["Super Admin", "Area Manager", "Branch Manager", "Experience Officer", "Land Lord"]
    },
  ];

  useEffect(() => {
    if (location.pathname.split("/")[1] === "operations") {
      settingTap(1);
    } else if (location.pathname.split("/")[1] === "finance") {
      settingTap(2);
    } else if (location.pathname.split("/")[1] === "community") {
      settingTap(3);
    } else if (location.pathname.split("/")[1] === "inventory") {
      settingTap(4);
    } else if (location.pathname.split("/")[1] === "settings") {
      settingTap(5);
    } else if (location.pathname.split("/")[1] === "tutorials") {
      settingTap(6);
    } else if (location.pathname.split("/")[1] === "currencies") {
      settingTap(7);
    } else {
      settingTap(0);
    }
  }, [location.pathname]);

  const settingTap = (id) => {
    localStorage.setItem("tap", id);
    setActiveTap(id);
  };

  const handleActiveTap = (index) => {
    localStorage.setItem("tap", index);
    setActiveTap(index);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // let locationsOptions =
  //   auth().role !== "Super Admin"
  //     ? auth()?.locations?.map((l) => ({
  //         value: l?._id,
  //         label: l?.name,
  //       }))
  //     : allLocations
  //         ?.filter((l) => !l?.isDeleted)
  //         ?.map((l) => ({
  //           value: l?._id,
  //           label: l?.name,
  //         }));
  // let locationsOptions = [];

  const locationsOptions = allLocations?.map((l) => ({
    value: l?._id,
    label: l?.name,
  }));

  return (
    <>
      <AppShell
        styles={{
          main: {
            background: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
          },
        }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        navbar={
          <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 300 }}>
            <div className="flex flex-col  font-semibold ">
              {links.map((l, index) =>
                l?.allowed?.includes(auth()?.role) || l?.allowed[0] === "*" ? (
                  <Link
                    key={index}
                    to={l.link}
                    onClick={() => handleActiveTap(index)}
                    className={`flex mt-1 w-full items-center  py-2 justify-center  gap-[15px] text-[#8E8E8E] rounded-[10px] hover:bg-[#00000010]  transition-all duration-150
               ${activeTap === index ? "bg-[#00000010] text-[black] " : " "}`}
                  >
                    <span className="w-[30px] h-[30px] flex justify-center items-center rounded-[10px] bg-white border-[1px] border-[#D9D9D9]">
                      <l.icon size={22} color="black" />
                    </span>
                    <span className="w-[100px]  text-[18px] ">{l.label}</span>
                  </Link>
                ) : null
              )}
            </div>
          </Navbar>
        }
        header={
          <Header height={{ base: 60, md: 70 }} p="md">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>
              <div className="flex justify-between w-full">
                <img
                  onClick={() => navigator("/")}
                  className="w-[120px] h-fit mx-[5%] object-contain cursor-pointer"
                  src={logo}
                  alt="Mqr logo"
                />

                <div className="flex gap-[20px] items-center mr-10">
                  {/* {!loadingLocations && (
                    
                  )} */}
                  <Select
                    // disabled={auth()?.role === "Experience Officer" || auth().role === "Branch Manager" ? true : false}
                    value={selectedLocation}
                    onChange={setSelectedLocation}
                    icon={<IconMapPin size="1.3rem" />}
                    transitionProps={{
                      transition: "pop-top-left",
                      duration: 80,
                      timingFunction: "ease",
                    }}
                    data={locationsOptions}
                    styles={(theme) => ({
                      wrapper: {
                        width: "320px",
                      },
                      item: {
                        fontFamily: "gilroy",
                        fontWeight: "600",
                        fontSize: "14px",
                        // applies styles to selected item
                        "&[data-selected]": {
                          "&, &:hover": {
                            backgroundColor: "#34B2C0",
                          },
                        },

                        "&[data-hovered]": {},
                      },
                    })}
                  />
                  <div className=" relative">
                    <button
                      className="bg-[#f6f8f7] h-[42px] rounded-[10px] min-w-[150px] text-[#797979] text-[16px]  font-semibold flex gap-2 items-center px-3 "
                      onClick={handleClick}
                    >
                      <img src={ProfileIcon} alt="My profile" className="w-[16px] h-[22px]" />
                      My profile
                    </button>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                      <MenuItem
                        onClick={() => navigator(`/settings/admin/${auth()._id}`)}
                        sx={{ fontSize: "16px" }}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem onClick={() => SignOut()} sx={{ fontSize: "16px" }}>
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </Header>
        }
      >
        {children}
      </AppShell>
    </>
  );
};
