import DeletePackage from "./DeletePackage";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import React, { useState, useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { http } from "../../../utils/httpCommon";
import { prettyNumber } from "../../../lib/prettyNumber";
import { Link } from "react-router-dom";
import Table from "../../../components/Table/Table";
import moment from "moment";
import { debounce } from "lodash";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import toast from "react-hot-toast";
import { Button } from "../../../components/Button/Button";
import { Controller, useForm } from "react-hook-form";
import ModalComponent from "../../../components/Modal/Modal2";
import { TextInput } from "../../../components/TextInput/TextInput";
import { ErrorMessage } from "@hookform/error-message";
import useFetchData from "../../../hooks/FetchData";
import * as yup from "yup";
import AsyncSelect from "react-select/async";
import { FormControl, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import { PasswordInput } from "@mantine/core";
import backArrow from "../../../assets/backarrow.svg";
import { yupResolver } from "@hookform/resolvers/yup";

const Packages = ({ isEdit }) => {
  const navigator = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(null);

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 800);
  };

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const onFilterChange = useCallback(async (filterModel) => {
    let searchVal = filterModel?.items[0]?.value;
    if (searchVal?.length > 0) {
      const res = await http.get(
        `/packages?limit=${pageState.pageSize}&page=${pageState.page}&location=${selectedLocation}`,
      );
      const rowsData = res?.data?.packageTiers?.map((d) => ({
        id: d._id,
        ...d,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.count,
      }));
    } else {
      debounced();
    }
  }, []);

  const { selectedLocation } = useContext(SelectedLocationContext);

  const fetchData = async (searchQuery) => {
    setPageState((old) => ({
      ...old,
      isLoading: true,
    }));

    try {
      const res = await http.get(
        `/packages?limit=${pageState.pageSize}&page=${pageState.page}&location=${selectedLocation}`,
      );
      const rowsData = res?.data?.data?.packages?.map((d) => ({
        id: d._id,
        ...d,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.data?.totalCount,
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || "Something went wrong");
    } finally {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize, selectedLocation]);

  const debounced = useCallback(debounce(fetchData, 700), []);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center py-2">
            <Link
              to={`/operations/package/${params.id}`}
              className=" object-cover h-[66px]  flex justify-center items-center font-semibold text-[16px] hover:text-[#34B2C0] transition-all"
            >
              {params?.row?.name}
            </Link>
          </div>
        );
      },
    },
    {
      field: "passes",
      headerName: "# Of Passes",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center ">
            <span className="font-medium transition-all text-[16px]   ">
              {params?.row?.passes || 0}
            </span>
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Original Price",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center ">
            <span className="font-medium transition-all text-[16px]   ">
              {prettyNumber(params?.row?.price) || 0}
            </span>
          </div>
        );
      },
    },
    {
      field: "discount",
      headerName: "Disocunt (%)",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center ">
            <span className="font-medium transition-all text-[16px]   ">
              {prettyNumber(params?.row?.discount) || 0}
            </span>
          </div>
        );
      },
    },
    {
      field: "priceAfterDiscount",
      headerName: "Price After discount",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center ">
            <span className="font-medium transition-all text-[16px]   ">
              {prettyNumber(params?.row?.priceAfterDiscount) || 0}
            </span>
          </div>
        );
      },
    },
    {
      headerName: "Actions",
      width: 400,
      renderCell: (params) => {
        return (
          <div className="flex gap-8 items-center ">
            {isEdit ? (
              <>
                <Link
                  to={`/operations/edit-package/${params?.row?._id}`}
                  className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                >
                  <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                    <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                  </div>
                  Edit
                </Link>
                <DeletePackage name={params?.row?.name} id={params?.row?._id} refetch={fetchData} />{" "}
              </>
            ) : (
              <Button
                classes={"w-max !text-[14px]"}
                onClick={() => {
                  setSelectedPackage(params.id);
                  setSubModal(true);
                }}
              >
                Subscribe User
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handleActionButton = () => {
    navigator("/operations/add-package");
  };

  const [subModal, setSubModal] = useState();
  const packageData = pageState?.data?.find((p) => p?._id === selectedPackage);

  return (
    <>
      <SubscribeUser
        opened={subModal}
        setOpened={setSubModal}
        packageId={selectedPackage}
        packageData={packageData}
      />
      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState?.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        fetchData={fetchData}
        onFilterChange={onFilterChange}
        columns={columns}
        title={"Packages"}
        actionButton={isEdit && "Add Package"}
        handleActionButton={handleActionButton}
      />
    </>
  );
};

export default Packages;

function SubscribeUser({ opened, setOpened, packageId, packageData }) {
  const [isClient, setIsClient] = useState(true);

  const { selectedLocation } = useContext(SelectedLocationContext);
  const { response: paymentMethods, loading: LoadingPaymentsMethods } = useFetchData(
    selectedLocation ? `financials/${selectedLocation}/payment-method-accounts` : null,
  );

  const [payWithCredit, setPayWithCredit] = useState(false);
  const [isCompany, setIsCompany] = useState(false);

  const schema = yup
    .object({
      client: yup
        .object()
        .nullable()
        .required(isClient ? "Client is required" : "Company is required"),
      paidAmount: yup
        .number()
        .required("Amount is required")
        .positive("Amount must be a positive number"),
      paymentMethodId: payWithCredit
        ? yup.string().optional()
        : yup
          .string()
          .typeError("Payment method paid is required")
          .required("payment method is required"),
    })
    .required();

  //client selection
  const loadOptions = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      let response = await http.get(`/client/find?q=${inputText}`);
      res = response?.data?.data;
      callback(
        res.map((i) => ({
          label: (
            <div className="flex items-center gap-2">
              {i?.profilePicture && (
                <img
                  className="w-[40px] h-[40px] rounded-full  object-cover"
                  src={i?.profilePicture}
                  alt={i.name}
                />
              )}{" "}
              {i.name}
            </div>
          ),
          value: i._id,
          ...i,
        })),
      );
    }
  };

  const loadOptionsCompanies = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      var data = JSON.stringify({
        textField: inputText,
        page: 1,
        limit: 10000,
      });
      let response = await http.post("/company/all/", data);
      res = response?.data?.data?.data;
      callback(
        res.map((i) => ({
          label: (
            <div className="flex items-center gap-3" key={i._id}>
              {i?.logo && (
                <img
                  src={i?.logo}
                  alt={i.name}
                  className="w-[40px] h-[40px] rounded-full object-cover"
                />
              )}
              {i.name}
            </div>
          ),
          value: i._id,
          ...i,
        })),
      );
    }
  };

  const CustomNoOptionsMessage = ({ inputValue, onResetClick }) => (
    <div className="p-2">
      No results found{" "}
      <button
        className=" text-[18px]  text-[#34B2C0]  hover:opacity-95"
        style={{ fontFamily: "gilroy-bold" }}
        onClick={() =>
          navigator(isClient ? `/community/clients/new-client` : "/community/companies/new-company")
        }
      >
        Add new
      </button>
    </div>
  );

  const navigator = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [step, setStep] = useState(1);
  const client = watch("client");
  const amount = watch("paidAmount");
  const passes = packageData?.passes;

  const onSubmit = async (passedData) => {
    console.log(passedData);
    if (step === 1) {
      setStep(2);
      return;
    }
    try {
      setSubmitLoading(true);
      const r = await http.post(`/packages/subscribe`, {
        package: packageId,
        clientId: passedData.client?.value,
        clientType: isClient ? "client" : "company",
        paidAmount: +passedData.paidAmount,
        paymentMethodId: payWithCredit ? 1 : +passedData.paymentMethodId,
        adminPassword: passedData.adminPassword,
        payWithCredit: payWithCredit,
        useCompanyCredit: payWithCredit && isCompany,
      });
      toast.success("Subscribed successfully");
      handleOnClose();
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleOnClose = () => {
    setStep(1);
    reset();
    setOpened(false);
  };
  return (
    <ModalComponent opened={opened} setOpened={setOpened} size={"xl"} onClose={handleOnClose}>
      {step === 1 ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="flex flex-col justify-center items-center gap-6 mt-4"
        >
          <div className="text-[24px] font-bold">Subscribe client to Package</div>
          <div className="flex items-center gap-4">
            <span>Client</span>
            <Switch
              checked={!isClient}
              onChange={() => {
                setIsClient(!isClient);
                setValue("client", null);
              }}
            />
            <span>Company</span>
          </div>

          <div className="w-full">
            <div className="mb-2 text-[20px]">{isClient ? "Client" : "Company"}</div>
            <div>
              <Controller
                control={control}
                name="client"
                render={({ field }) => (
                  <AsyncSelect
                    components={{
                      NoOptionsMessage: ({ inputValue }) => (
                        <CustomNoOptionsMessage inputValue={inputValue} onResetClick={() => { }} />
                      ),
                    }}
                    isClearable
                    defaultOptions
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: "56px",
                        borderRadius: "8px",
                        borderColor: "black",
                        "&:hover": {},
                      }),
                      option: (styles) => ({
                        ...styles,
                        zIndex: "999 !important",
                        backgroundColor: "white !important",
                      }),
                    }}
                    {...field}
                    placeholder={isClient ? "Search for Client" : "Search for Company"}
                    loadOptions={isClient ? loadOptions : loadOptionsCompanies}
                  />
                )}
              />
            </div>
            <p className="text-red-500">{errors.client?.message}</p>
          </div>

          <>
            <div className="flex justify-start  flex-grow w-full items-center">
              <div className="w-full  my-2 bg-white py-1 bg-black  text-[18px]  font-bold ">
                Pay with Credit
              </div>
              <Switch
                checked={payWithCredit}
                onChange={(_, checked) => setPayWithCredit(checked)}
              />
            </div>

            {payWithCredit && (
              <div className="flex items-center gap-4 justify-center w-full">
                <span>Personal</span>
                <Switch checked={isCompany} onChange={() => setIsCompany(!isCompany)} />
                <span>Company</span>
              </div>
            )}
          </>

          {!payWithCredit && (
            <div className="w-full">
              <FormControl fullWidth sx={{ border: "none !important" }}>
                <InputLabel>Payment Method</InputLabel>
                <Controller
                  control={control}
                  name="paymentMethodId"
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <Select
                      sx={{
                        color: "black",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#EFEFEF",
                        },
                      }}
                      onBlur={onBlur}
                      onChange={onChange}
                      variant="outlined"
                      labelId="Payment Method-label"
                      id="Payment Method"
                      value={value}
                      label="Payment Method"
                      inputRef={ref}
                      defaultValue=""
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {paymentMethods?.map((l) => {
                        return (
                          l?.name.toLowerCase() !== "receivable" && (
                            <MenuItem key={l?.qboId} value={l?.qboId}>
                              {l?.name}
                            </MenuItem>
                          )
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
              <p className="text-red-500">{errors.paymentMethodId?.message}</p>
            </div>
          )}

          <div className="w-full">
            <TextInput
              label={"Paid Amount"}
              type="number"
              defaultValue={0}
              {...register("paidAmount", { required: true })}
            ></TextInput>

            <ErrorMessage
              name="paidAmount"
              errors={errors}
              render={({ message }) => <p className="text-red-500">{message}</p>}
            />
          </div>

          <div className="flex justify-around  w-full">
            <Button secondary={true} invert={true} onClick={() => setOpened(false)}>
              Cancel
            </Button>
            <Button loading={submitLoading} secondary={true} type="submit">
              Confirm
            </Button>
          </div>
        </form>
      ) : (
        <div className="flex flex-col relative px-10 ">
          <div className="flex gap-4 ">
            <div
              onClick={() => setStep(1)}
              className="cursor-pointer flex justify-center items-center w-[40px] aspect-square rounded-full bg-[#EFEFEF] hover:[##686868] "
            >
              <img src={backArrow} alt="back" className="w-[8px] h-[15px] object-contain" />
            </div>
            <div
              className="text-[28px] font-bold"
              style={{
                fontFamily: "gilroy-bold",
              }}
            >
              Subscribe User to Package
            </div>
          </div>

          <div className="my-4 flex flex-col gap-3">
            <div className="text-[#797979] text-[16px] font-semibold">
              {isClient ? "Client" : "Company"} information
            </div>
            <div
              to={`/community/client/${client?._id}`}
              className="border-[#E4E4E4] border-[1px] rounded-[10px] p-2 flex gap-3"
            >
              <img
                src={client?.profilePicture || client?.logo}
                alt={client?.name}
                className="rounded-[10px] w-[75px] object-cover h-[75px] "
              />
              <div className="flex flex-col justify-center w-full  ">
                <div className="text-[20px] " style={{ fontFamily: "gilroy-bold" }}>
                  {client?.name}
                </div>
                {client?.company && (
                  <div className="text-[#797979]">Member at " {client?.company?.name}"</div>
                )}
              </div>
            </div>
          </div>

          <div className="text-[16px]">
            I Admin confirm that <strong>{client?.name}</strong> paid the amount of{" "}
            <strong>{prettyNumber(+amount)} EGP</strong> for{" "}
            {passes && prettyNumber(+passes) + " passes in package "}{" "}
            <strong>{packageData?.name}</strong>
          </div>

          <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-10 ">
              <PasswordInput
                required
                size="lg"
                radius={"md"}
                placeholder="Admin Passowrd"
                {...register("adminPassword")}
              />
              <p className="text-red-500">{errors.adminPassword?.message}</p>
            </div>

            <div className="flex w-full justify-end  mt-4 gap-5">
              <Button secondary={true} invert={true} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button loading={submitLoading} type="submit" secondary={true}>
                Confirm
              </Button>
            </div>
          </form>
        </div>
      )}
    </ModalComponent>
  );
}
