import React, { useState, useCallback, useEffect, useContext } from "react";
import { http } from "../../../utils/httpCommon";
import { Link } from "react-router-dom";
import Table from "../../../components/DataGrid/Table";
import NewRentContractModal from "./NewRentContractModal/NewRentContractModal";
import moment from "moment";
import { debounce } from "lodash";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import { useAuthUser } from "react-auth-kit";
import { FormControl, styled, Switch } from "@mui/material";

const RentContractsTable = () => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const { selectedLocation } = useContext(SelectedLocationContext);
  const [includeCancelled, setIncludeCancelled] = useState(false);

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 800);
  };

  const auth = useAuthUser();

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const onFilterChange = useCallback(async (filterModel) => {
    let searchVal = filterModel?.items[0]?.value;

    if (searchVal?.length > 0) {
      const res = await http.get(
        `/rent-contracts?location=${selectedLocation}&page=${pageState.page}&limit=${pageState.pageSize}&search=${searchQuery}&includeCancelled=${includeCancelled}`,
      );

      const rowsData = res?.data?.documents?.map((d) => ({
        id: d._id,
        ...d,
        companyName: d?.company?.name,
        companyLeader: d?.company?.leader?.name,
        facilityName: d?.firstFacility?.name,
        facilitiesLength: d?.facility?.length,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.metadata[0]?.total,
      }));
    } else {
      debounced();
    }
  }, []);

  const fetchData = async (searchQuery = "") => {
    setPageState((old) => ({
      ...old,
      isLoading: true,
    }));
    try {
      const res = await http.get(
        `/rent-contracts?location=${selectedLocation}&page=${pageState.page}&limit=${pageState.pageSize}&search=${searchQuery}&includeCancelled=${includeCancelled}`,
      );

      const rowsData = res?.data?.documents?.map((d) => ({
        id: d._id,
        ...d,
        companyName: d?.company?.name,
        companyLeader: d?.company?.leader?.name || "n/a",
        facilityName: d?.firstFacility?.name,
        facilitiesLength: d?.facility?.length,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.metadata[0]?.total,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize, selectedLocation, includeCancelled]);

  const debounced = useCallback(debounce(fetchData, 1000), []);

  const columns = [
    {
      field: "companyName",
      headerName: "Company",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center py-2">
            <Link
              to={`/operations/rent-contract/${params.row.id}`}
              className=" object-cover h-[66px]  flex justify-center items-center font-semibold text-[16px] hover:text-[#34B2C0] transition-all"
            >
              {params?.row?.companyName}
            </Link>
          </div>
        );
      },
    },

    {
      field: "companyLeader",
      headerName: "Company Admin",
      width: 220,
      renderCell: (params) => {
        return (
          <Link
            to={
              params?.row?.company?.leader
                ? `/community/client/${params?.row?.company?.leader?._id}`
                : "#"
            }
            className="flex gap-[10px] items-center "
          >
            <span className="  font-medium hover:text-[#34B2C0] transition-all text-[16px]   ">
              {params?.row?.companyLeader}
            </span>
          </Link>
        );
      },
    },

    {
      field: "facilityName",
      headerName: "Facility",
      width: 200,
      renderCell: (params) => {
        return (
          <Link
            to={`/inventory/facility/${params?.row?.firstFacility?._id}`}
            className="flex gap-[10px] items-center  hover:text-[#34B2C0]  "
          >
            <span className="text-[16px]">
              <b>{params.row.facilityName}</b>
              {params.row.facilitiesLength > 1
                ? ` and ${params.row.facilitiesLength - 1} others`
                : ``}
            </span>
          </Link>
        );
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center ">
            <span className=" text-[16px]   ">
              <>
                {moment(params.row.startDate).format(" Do MMM  YYYY")}- <br />
                {moment(params.row.endDate).format(" Do MMM  YYYY")}{" "}
              </>
            </span>
          </div>
        );
      },
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 200,
      renderCell: (params) => {
        const statusButtonStyles = {
          fully_paid:
            " text-[#00AD3B] capitalize bg-[#00ad3b33]  font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]  ",
          partially_paid:
            " text-yellow-600 capitalize  bg-yellow-100 bg-opacity-20 font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]   ",
          unpaid:
            " text-red-500  bg-red-100 capitalize font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]   ",
        };

        return (
          <div className="flex gap-[10px] items-center ">
            <span className={statusButtonStyles[params.row.paymentStatus]}>
              {params.row.paymentStatus.replace("_", " ")}
            </span>
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Contract Status",
      width: 200,
      renderCell: (params) => {
        const statusButtonStyles = {
          pending:
            " text-[#797979] bg-[#EFEFEF]  font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]  ",
          active:
            " text-[#34B2C0]  bg-[#3bb2c0] bg-opacity-20 font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]   ",
          cancelled:
            " text-red-500  bg-[#EFEFEF] font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]   ",
          completed:
            " text-[#00AD3B] bg-[#00ad3b33]  font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]  ",
        };
        return (
          <div className="flex gap-[10px] items-center  w-[130px] capitalize">
            <div className={statusButtonStyles[params.row.status]}>{params.row.status}</div>
          </div>
        );
      },
    },
  ];

  const handleActionButton = () => {
    setOpenRentContractModal(true);
  };
  const [openRentContractModal, setOpenRentContractModal] = useState(false);
  const [rentContractModalStep, setRentContractModalStep] = useState(1);

  return (
    <>
      <NewRentContractModal
        rentContractModalStep={rentContractModalStep}
        setRentContractModalStep={setRentContractModalStep}
        opened={openRentContractModal}
        setOpened={setOpenRentContractModal}
        fetchData={fetchData}
      />

      <div className="flex justify-between items-center">
        <div className="w-full my-2 bg-white py-1 text-[18px] font-bold">Include Cancelled</div>
        <Switch value={includeCancelled} onChange={(_, checked) => setIncludeCancelled(checked)} />
      </div>

      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState?.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        keys={["id", "name", "phone", "email", "location", "role"]}
        title={"Companies"}
        actionButton={"Add Rent Contract"}
        onFilterChange={onFilterChange}
        handleActionButton={handleActionButton}
      />
    </>
  );
};

export default RentContractsTable;
