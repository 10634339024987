import React, { useContext, useEffect, useState } from "react";
import useFetchPostData from "../../../hooks/FetchPostData";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { http } from "../../../utils/httpCommon";
import useFetchData from "../../../hooks/FetchData";
import MonthView from "./MonthView";
import moment from "moment";
import ListView from "./ListView";
import { useDisclosure } from "@mantine/hooks";
import { Drawer } from "@mantine/core";
import DayView from "./DayView";
import CalendarForm from "./Forms/CalendarForm";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import { useAuthUser } from "react-auth-kit";

const Calender = () => {
  const { selectedLocation } = useContext(SelectedLocationContext);

  const {
    response: facilities,
    loading: loadingFacilities,
    fetchData: fetchFacilites,
  } = useFetchPostData("/facility/all", {
    page: 1,
    limit: 1000,
    location: selectedLocation,
  });

  const { response: paymentMethods, loading: loadingPaymentMethod } = useFetchData(
    `financials/${selectedLocation || "none"}/payment-method-accounts`
  );
  const auth = useAuthUser();

  const [selectedDate, setSelectedDate] = useState(moment());
  const [currMonth, setCurrMonth] = useState(new Date());

  const [appointmentsDataLoading, setAppointmentsDataLoading] = useState(false);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const fetchAppointmentsData = async () => {
    setAppointmentsDataLoading(true);
    const from =
      viewState === "Day"
        ? selectedDate.format("YYYY-MM-DD")
        : viewState === "Month"
        ? moment(currMonth).set("date", 1).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");

    try {
      const res = await http.get(
        `/bookings?baseForm=true?page=1&limit=100000&location=${selectedLocation}&from=${from}`
      );

      let flatedData = res?.data?.data.flatMap((item) => {
        const days = item.days.map((day) => {
          return {
            ...day,
            bookingId: item?._id,
            location: item?.location,
            isConfirmed: item?.isConfirmed,
            isDeleted: item?.isDeleted,
            facility: item?.facility,
            isReceivable: Boolean(item?.receivable),
            user: item?.user,
            createdAt: item?.createdAt,
            updatedAt: item?.updatedAt,
            invoices: item?.invoice ?? [],
            totalWithDiscount: item?.totalWithDiscount ?? 0,
            // ...item.location,
          };
        });
        return days;
      });
      setAppointmentsData(
        flatedData.map((a) => ({
          ...a,
          id: a?._id,
          startDate: new Date(a.startTime * 1000),
          endDate: new Date(a.endTime * 1000),
          roomId: a?.facility?._id,
          location: a?.facility?.name,
        }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setAppointmentsDataLoading(false);
    }
  };

  const fetchAppointmentsDataAfterDelete = async () => {
    try {
      const res = await http.get(
        `/bookings?baseForm=true?page=1&limit=100000&location=${selectedLocation}`
      );
      let flatedData = res?.data?.data.flatMap((item) => {
        const days = item.days.map((day) => {
          return {
            ...day,
            bookingId: item?._id,
            location: item?.location,
            isConfirmed: item?.isConfirmed,
            isReceivable: Boolean(item?.receivable),
            isDeleted: item?.isDeleted,
            facility: item?.facility,
            user: item?.user,
            createdAt: item?.createdAt,
            updatedAt: item?.updatedAt,
            invoices: item?.invoice ?? [],
            totalWithDiscount: item?.totalWithDiscount ?? 0,
            // ...item.location,
          };
        });
        return days;
      });
      setAppointmentsData(
        flatedData.map((a) => ({
          ...a,
          id: a?._id,
          startDate: new Date(a.startTime * 1000),
          endDate: new Date(a.endTime * 1000),
          roomId: a?.facility?._id,
          location: a?.facility?.name,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const [viewState, setViewState] = useState("Day");

  useEffect(() => {
    fetchAppointmentsData();
    fetchFacilites();
  }, [selectedLocation, selectedDate, currMonth, viewState]);

  const [opened, { open, close }] = useDisclosure(false);

  const [selectedTimeSlotData, setSelectedTimeSlotData] = useState({
    time: "",
    date: "",
    facility: {},
  });

  return (
    <div className="relative ">
      {!appointmentsDataLoading ? (
        <>
          <Drawer
            opened={opened}
            onClose={() => {
              close();
              fetchAppointmentsDataAfterDelete();
            }}
            position="right"
            size={"lg"}
            overlayProps={{ opacity: 0.6, blur: 5 }}
          >
            <CalendarForm
              selectedTimeSlotData={selectedTimeSlotData}
              open={open}
              close={close}
              facilities={facilities}
              paymentMethods={paymentMethods}
              fetchAppointmentsData={fetchAppointmentsData}
              fetchAppointmentsDataAfterDelete={fetchAppointmentsDataAfterDelete}
            />
          </Drawer>
          <div className="flex justify-between relative">
            <div className="flex ">
              <button
                className={`${
                  viewState === "Day" ? "bg-[#EFEFEF]" : "bg-[#F8F9FA]"
                }  transition-all duration-100 text-black rounded-tl-[10px] rounded-bl-[10px]  border-[1px] border-[#E4E4E4] font-bold px-[15px] py-[10px] text-[18px] h-fit`}
                onClick={() => setViewState("Day")}
              >
                Day
              </button>
              <button
                className={`${
                  viewState === "Month" ? "bg-[#EFEFEF]" : "bg-[#F8F9FA]"
                } transition-all duration-100 text-black border-t-[1px] border-b-[1px] border-r-[1px]  border-[#E4E4E4] font-bold px-[15px] py-[10px] text-[18px] h-fit`}
                onClick={() => setViewState("Month")}
              >
                Month
              </button>
              <button
                className={`${
                  viewState === "List" ? "bg-[#EFEFEF]" : "bg-[#F8F9FA]"
                } transition-all duration-100 text-black rounded-tr-[10px] rounded-br-[10px] border-t-[1px]   border-b-[1px]  border-r-[1px] border-[#E4E4E4] font-bold px-[15px] py-[10px] text-[18px] h-fit`}
                onClick={() => setViewState("List")}
              >
                List
              </button>
            </div>
            {auth().role !== "Land Lord" && (
              <button
                onClick={open}
                className="mr-[5%] w-[50px] h-[50px] bg-[#34B2C0] rounded-full flex justify-center items-center text-[#EFEFEF] text-[40px] shadow-2xl shadow-[#DEDEDE] hover:opacity-90 transition-all duration-100 "
              >
                +
              </button>
            )}
          </div>
          {facilities?.count === 0 ? (
            <div className="flex justify-center items-center mt-10 text-[20px] font-bold ">
              No Facilities in this location yet
            </div>
          ) : (
            <>
              {viewState === "Day" ? (
                <DayView
                  open={open}
                  close={close}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  facilities={facilities}
                  paymentMethods={paymentMethods}
                  appointmentsData={appointmentsData}
                  selectedTimeSlotData={selectedTimeSlotData}
                  setSelectedTimeSlotData={setSelectedTimeSlotData}
                  fetchAppointmentsData={fetchAppointmentsData}
                  fetchAppointmentsDataAfterDelete={fetchAppointmentsDataAfterDelete}
                />
              ) : viewState === "Month" ? (
                <MonthView
                  currentDate={currMonth}
                  setCurrentDate={setCurrMonth}
                  facilities={facilities}
                  paymentMethods={paymentMethods}
                  appointmentsData={appointmentsData}
                  fetchAppointmentsData={fetchAppointmentsData}
                  fetchAppointmentsDataAfterDelete={fetchAppointmentsDataAfterDelete}
                />
              ) : (
                <ListView
                  appointmentsData={appointmentsData}
                  fetchAppointmentsData={fetchAppointmentsData}
                />
              )}
            </>
          )}
        </>
      ) : (
        <LoadingCircle />
      )}
    </div>
  );
};

export default Calender;
