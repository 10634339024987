import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { http } from "../../../utils/httpCommon";
import { debounce } from "lodash";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import { useAuthUser } from "react-auth-kit";
import ModalComponent from "../../../components/Modal/Modal";
import { TextInput } from "../../../components/TextInput/TextInput";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "../../../components/Button/Button";
import { useForm } from "react-hook-form";

const Subscriptions = () => {
  const { selectedLocation } = useContext(SelectedLocationContext);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [searchQuery, setSearchQuery] = React.useState("");
  const actionsRole = ['Super Admin'];
  const auth = useAuthUser();

  const handleChange = (event) => {
    debounced(event.target.value, 600);
    setSearchQuery(event.target.value);
  };

  const [toCancel, setToCancel] = useState();
  const [cancelModal, toggleCancelModal] = useState(false);
  const handleCancel = async (itemId) => {
    console.log({ "from handleCancel": itemId });

    setToCancel(itemId);
    // toggleCancelModal(true);
  };

  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 380,
      renderCell: (params) => {
        return (
          <Link
            to={`/finance/subscriptions/${params.row?._id}`}
            className="flex flex-col gap-[5px] h-[80px] text-[16px]  justify-center hover:text-[#34B2C0]"
          >
            <div className="font-semibold">
              {params?.row?.clientType === "company"
                ? params?.row?.company?.name
                : params.row?.client?.name}
            </div>
          </Link>
        );
      },
    },
    {
      field: "passes",
      headerName: "Passes",
      width: 150,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] ">{params?.row?.passess}</div>
        );
      },
    },
    {
      field: "usedPasses",
      headerName: "Used Passes",
      width: 150,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] ">
            {params?.row?.usedPassess}
          </div>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] font-semibold">
            {params?.row?.isActive ? "Active" : "In Active"}
          </div>
        );
      },
      // editable: true,
    },

    {
      field: "startDate",
      headerName: "Start Date",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.startDate ? new Date(params?.row?.startDate).toLocaleDateString() : "-"}
          </div>
        );
      },
      // editable: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.endDate ? new Date(params?.row?.endDate).toLocaleDateString() : "-"}
          </div>
        );
      },
      // editable: true,
    },
    actionsRole.includes(auth().role) && {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      width: 200,

      renderCell: (params) => {
        return (
          <>
            <div className="flex flex-col gap-[15px]">
              <button
                onClick={() => {
                  handleCancel(params?.row?._id)
                }}
                className="flex items-center  justify-center font-semibold  text-[20px] text-center min-w-[120px] w-fit rounded-lg py-2 px-4 duration-150 transition  bg-black text-white font-bold border-[1px]   hover:border-[1px]  hover:border-[#8E8E8E]  hover:bg-white  hover:text-[#8E8E8E]  transition-all duration-150"
              >
                Cancel
              </button>
            </div>

          </>
        );
      },
    },
  ];
  const fetchData = async (searchValues) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.get(
        `/packages/subscriptions/location/${selectedLocation}?page=${pageState.page}&limit=${pageState.pageSize}`,
      );
      const rowsData = res.data?.data?.subscriptions?.map((d) => ({
        id: d._id,
        ...d,
      }));
      console.log(rowsData, res?.data, "rowsData");
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.data?.totalCount,
      }));
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };
  const debounced = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize, selectedLocation, searchQuery]);

  return (
    <div>
      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={Math.ceil(pageState.total / pageState.pageSize)}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        keys={["client", "passes", "usedPasses", "isActive", "startDate", "endDate", "actions"]}
        title={"Subscriptions"}
        removeActionButton={true}
      />

      <CancelSubscriptionModal
        fetchData={fetchData}
        opened={toCancel ? true : false}
        setToCancel={setToCancel}
        item={toCancel}
      />
    </div>
  );
};

export default Subscriptions;

function CancelSubscriptionModal({ fetchData, opened, setToCancel, item }) {
  console.log({ item });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    adminPassword: "",
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = async (passedData) => {
    try {
      setSubmitLoading(true);
      if (!item) {
        toast.error("Item id is empty, Contact dev team.");
        return false;
      }

      const r = await http.put(`/packages/subscriptions/cancel/${item}`, {
        adminPassword: passedData.adminPassword,
      });
      toast.success("Subscription has been canceled successfully");

      reset();
      setToCancel(undefined);
      fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <ModalComponent opened={opened} setOpened={setToCancel}>
      <div className="text-[24px] font-bold">Cancel subscription</div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-center gap-[40px] mt-4"
      >
        <div className="w-[100%]">
          <TextInput
            label={"Password"}
            type="password"
            {...register("adminPassword", { required: true })}
          ></TextInput>
          <ErrorMessage
            name="adminPassword"
            errors={errors}
            render={({ message }) => <p className="text-red-500">{message}</p>}
          />
        </div>

        <div className="flex justify-around  w-full">
          <Button secondary={true} invert={true} onClick={() => setToCancel(undefined)}>
            Cancel
          </Button>
          <Button loading={submitLoading} secondary={true} type="submit">
            Confirm
          </Button>
        </div>
      </form>
    </ModalComponent>
  );
}