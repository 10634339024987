import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import useFetchData from "../../../hooks/FetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Controller, useWatch } from "react-hook-form";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import ModalComponent from "../../../components/Modal/Modal2";
import { toast } from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import backArrow from "../../../assets/backarrow.svg";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useMemo } from "react";
import { debounce } from "lodash";
import { Checkbox, PasswordInput } from "@mantine/core";
import { OutlinedInput, Switch, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { prettyNumber } from "../../../lib/prettyNumber";

export const CustomNoOptionsMessage = ({ inputValue, onResetClick, navigator }) => (
  <div className="p-2">
    <p>No results found</p>
    <button
      className=" text-[18px]  text-[#34B2C0]  hover:opacity-95"
      style={{ fontFamily: "gilroy-bold" }}
      onClick={() => navigator(`/community/clients/new-client`)}
    >
      Add new client
    </button>
  </div>
);

const CheckInModal = ({ opened, setOpened, data, fetchData }) => {
  const [steps, setSteps] = useState(0);
  const [payWithCredit, setPayWithCredit] = useState(false);
  const [payWithPackage, setPayWithPackage] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const { selectedLocation } = useContext(SelectedLocationContext);
  const [loadingActivePromoCodes, setLoadingActivePromoCodes] = useState(false);
  const [activePromoCodes, setActivePromoCodes] = useState([]);
  const [receivable, setReceivable] = useState(false);
  const [recievableDate, setRecievableDate] = useState(null);

  //TO DO reasons for termintation
  const { response: locations, loading } = useFetchData("/location/me");

  const paymentMethodsValidation = useCallback(() => {
    if (payWithCredit || receivable || payWithPackage) {
      return yup.string().optional();
    } else {
      return yup
        .number()
        .typeError("Payment method is a required field")
        .required("Payment method is a required field");
    }
  }, [payWithCredit, receivable, payWithPackage]);

  const paidAmountValidation = useCallback(() => {
    if (receivable || payWithPackage || payWithCredit) {
      return yup.string().optional();
    } else {
      return yup
        .number()
        .positive()
        .typeError("Paid amount is a required field")
        .required("Paid amount is a required field");
    }
  }, [receivable, payWithPackage, payWithCredit]);

  const schema = yup
    .object({
      location: yup.string().required("Location is a required field"),
      user: yup.object().typeError("User is a required field").required("User is a required field"),
      paymentMethod: paymentMethodsValidation(),
      discountType: yup.string().required("Discount type is required"),
      adminPassword:
        steps === 0 ? yup.string() : yup.string().required("Admin password is required"),
      discount: yup.string(),
      paidAmount: paidAmountValidation(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      discountType: "percentage",
    },
  });

  const discountTypeValue = useWatch({
    control,
    name: "discountType",
  });

  const location = locations.find((l) => l._id === watch("location"));
  const paymentID = watch("paymentMethod") || "";
  const promoCode = watch("promoCode") || "";
  const discount = +watch("discount");
  const user = watch("user");
  const [pricing, setPricing] = useState({
    subTotal: null,
    vat: null,
    totalWithVat: null,
    totalDiscounted: null,
    discountAmount: null,
    promoCodeAmount: null,
  });

  console.log(errors, "errors");
  useEffect(() => {
    watch("paymentMethod");
    paymentMethodsValidation();
  }, [payWithCredit, watch, errors, paymentMethodsValidation]);

  const { response: paymentMethods, loading: loadingPaymentMethod } = useFetchData(
    location?._id ? `financials/${location._id}/payment-method-accounts` : null,
  );

  const fetchActivePromoCodes = async () => {
    setLoadingActivePromoCodes(true);
    try {
      const activePromoCodes = await http.get("/promo-codes/active");
      setActivePromoCodes(activePromoCodes.data?.data);
    } catch (error) {
      console.log(error);
    }
    setLoadingActivePromoCodes(false);
  };

  const debouncedFetchActivePromoCodes = useCallback(debounce(fetchActivePromoCodes, 500), []);

  useEffect(() => {
    debouncedFetchActivePromoCodes();
  }, []);

  const loadPromoCodeOptions = async (inputValue) => {
    if (!inputValue) {
      return activePromoCodes.map((code) => ({
        label: code.name,
        value: code._id,
      }));
    }

    const filtered = activePromoCodes.filter((code) =>
      code.name.toLowerCase().includes(inputValue.toLowerCase()),
    );

    return filtered.map((code) => ({
      label: code.name,
      value: code._id,
    }));
  };

  const calcPrice = async (location, discount, promoCode, discountTypeValue) => {
    if (!location?._id) return false;
    try {
      const result = await http.get(
        `/checks/calc?location=${
          location?._id
        }&discount=${discount}&discountType=${discountTypeValue}&promoCodeId=${
          promoCode?.value || ""
        }`,
      );
      setPricing(result.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };

  const debouncedCalcPrice = useCallback(
    debounce(
      (location, discount, promoCode, discountTypeValue) =>
        calcPrice(location, discount, promoCode, discountTypeValue),
      500,
    ),
    [],
  );

  useEffect(() => {
    debouncedCalcPrice(location, discount, promoCode, discountTypeValue);
  }, [location?._id, discount, user?._id, promoCode, discountTypeValue]);

  const setCustomValue = (id, value) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = async (passedData) => {
    if (steps === 0) {
      setSteps(1);
    } else {
      setSubmitLoading(true);
      try {
        const result = await http.post(
          `/checks/in`,
          JSON.stringify({
            userId: passedData.user._id,
            location: passedData.location ? passedData.location : selectedLocation,
            promoCode: passedData.promoCode?.value || null,
            discount: +Number(passedData.discount).toFixed(2),
            discountType: passedData.discountType,
            adminPassword: passedData.adminPassword,
            notes: passedData.notes,
            paidAmount: receivable ? pricing.totalDiscounted : passedData.paidAmount,
            receivable: receivable,
            receivableDate: recievableDate
              ? moment(recievableDate).format("YYYY-MM-DD")
              : undefined,
            // checkInUsingCredit: !receivable && payWithCredit,
            paymentMethodId:
              !receivable && !payWithCredit && !payWithPackage ? passedData.paymentMethod : null,
            usePackage: payWithPackage,
            useCompanyPackage: payWithPackage && isCompany,
            checkInUsingCredit: !receivable && !payWithPackage ? payWithCredit : false,
            useCompanyCredit: !receivable && !payWithPackage ? payWithCredit && isCompany : false,
          }),
        );
        handleOnClose();
        fetchData();
        toast.success("check in created successfully");
      } catch (error) {
        toast.error(error.response?.data?.message || error.message || "Something went wrong");
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  const handleOnClose = () => {
    setOpened(false);
    reset({
      user: "",
      location: "",
      paymentMethod: "",
      adminPassword: "",
      promoCode: "",
      discount: "",
    });
    setPayWithCredit(false);
    setSteps(0);
    setReceivable(false);
    setRecievableDate(null);
  };

  const loadOptions = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      let response = await http.get(`/client/find?q=${inputText}`);
      res = response?.data?.data;
      callback(
        res.map((i) => ({
          label: (
            <div className="flex items-center gap-2">
              {i?.profilePicture && (
                <img
                  className="w-[40px] h-[40px] rounded-full  object-cover"
                  src={i?.profilePicture}
                  alt={i.name}
                />
              )}{" "}
              {i.name}
            </div>
          ),
          value: i._id,
          ...i,
        })),
      );
    }
  };

  const navigator = useNavigate();

  const onPayWithPackageChange = (checked) => {
    setPayWithPackage(checked);
    setReceivable(false);
  };

  const generateSentBody = (passedData) => {
    const isPayWithPackage = payWithPackage;
    // return {
    //   user: passedData.user._id,
    //   location: passedData.location ? passedData.location : selectedLocation,
    //   promoCode: passedData.promoCode?.value || null,
    //   discount: +Number(passedData.discount).toFixed(2),
    //   discountType: passedData.discountType,
    //   adminPassword: passedData.adminPassword,
    //   notes: passedData.notes,
    //   paidAmount: receivable ? pricing.totalDiscounted : passedData.paidAmount,
    //   receivable: receivable,
    //   receivableDate: recievableDate
    //     ? moment(recievableDate).format("YYYY-MM-DD")
    //     : undefined,
    //   checkInUsingCredit: !receivable && payWithCredit,
    //   paymentMethodId:
    //     !receivable && !payWithCredit && !payWithPackage ? passedData.paymentMethod : null,
    //   usePackage: payWithPackage,
    //   useCompanyPackage: payWithPackage && isCompany,
    // };
  };
  return (
    <ModalComponent size={"lg"} opened={opened} setOpened={setOpened} onClose={handleOnClose}>
      {!loading || !loadingPaymentMethod || !loadingActivePromoCodes ? (
        steps === 0 ? (
          <div className="flex flex-col px-10">
            <div className="text-[30px] font-bold" style={{ fontFamily: "gilroy-bold" }}>
              New Check in
            </div>

            <form className="w-full h-full " onSubmit={handleSubmit(onSubmit)}>
              <div className="my-4">
                <div className="mb-2 text-[20px]">Client</div>
                <div>
                  <AsyncSelect
                    components={{
                      NoOptionsMessage: ({ inputValue }) => (
                        <CustomNoOptionsMessage
                          inputValue={inputValue}
                          onResetClick={() => {}}
                          navigator={navigator}
                        />
                      ),
                    }}
                    isClearable
                    defaultOptions
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: "56px",
                        borderRadius: "8px",
                        borderColor: "black",
                        "&:hover": {},
                      }),
                      option: (styles) => ({
                        ...styles,
                        zIndex: "999 !important",
                        backgroundColor: "white !important",
                      }),
                    }}
                    value={user}
                    onChange={(value) => {
                      setCustomValue("user", value);
                    }}
                    placeholder={"Search for Client"}
                    loadOptions={loadOptions}
                  />
                </div>
                <p className="text-red-500">{errors.user?.message}</p>
              </div>

              <div className="my-4 ">
                <FormControl fullWidth sx={{ border: "none !important" }}>
                  <InputLabel>Location</InputLabel>
                  <Controller
                    control={control}
                    name="location"
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                      <Select
                        sx={{
                          color: "black",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#EFEFEF",
                          },
                        }}
                        name={name}
                        onBlur={onBlur}
                        onChange={(e, { props }) => {
                          onChange(props.value);
                        }}
                        variant="outlined"
                        labelId="location label"
                        id="location"
                        value={location?._id}
                        label="Location"
                        inputRef={ref}
                        defaultValue={""}
                      >
                        <MenuItem value={null}>
                          <em>None</em>
                        </MenuItem>
                        {locations.map((l) => (
                          <MenuItem key={l?._id} value={l?._id}>
                            {l?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <p className="text-red-500">{errors.location?.message}</p>
              </div>
              {!payWithCredit && !receivable && (
                <div className="flex justify-between items-center">
                  <div className="w-full  my-2 bg-white py-1  text-[18px]  font-bold ">
                    Pay with Package
                  </div>
                  <Switch
                    checked={payWithPackage}
                    onChange={(_, checked) => onPayWithPackageChange(checked)}
                  />
                </div>
              )}

              {payWithPackage && (
                <div className="flex items-center gap-4 justify-center w-full">
                  <span>Personal</span>
                  <Switch checked={isCompany} onChange={() => setIsCompany(!isCompany)} />
                  <span>Company</span>
                </div>
              )}

              {!payWithPackage && (
                <>
                  {!receivable && (
                    <>
                      <div className="flex justify-between items-center">
                        <div className="w-full  my-2 bg-white py-1  text-[18px]  font-bold ">
                          Pay with Credit
                        </div>
                        <Switch
                          checked={payWithCredit}
                          onChange={(_, checked) => setPayWithCredit(checked)}
                        />
                      </div>

                      {payWithCredit && (
                        <div className="flex items-center gap-4 justify-center w-full">
                          <span>Personal</span>
                          <Switch checked={isCompany} onChange={() => setIsCompany(!isCompany)} />
                          <span>Company</span>
                        </div>
                      )}
                    </>
                  )}

                  <div className="my-4">
                    <FormControl fullWidth sx={{ border: "none !important" }}>
                      <Controller
                        control={control}
                        name="promoCode"
                        render={({ field }) => (
                          <AsyncSelect
                            {...field}
                            isClearable
                            defaultOptions
                            loadOptions={loadPromoCodeOptions}
                            placeholder="Select Promo Code"
                            noOptionsMessage={() => {
                              if (activePromoCodes.length === 0) {
                                return "No valid promo codes available";
                              } else {
                                return "There is no matching promo codes !";
                              }
                            }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: "56px",
                                borderRadius: "8px",
                                borderColor: "#EFEFEF",
                                "&:hover": {
                                  borderColor: state.isFocused ? "#34B2C0" : "#EFEFEF",
                                },
                                boxShadow: state.isFocused ? "0 0 0 1px #34B2C0" : "none",
                              }),
                              option: (styles, { data, isFocused, isSelected }) => ({
                                ...styles,
                                backgroundColor: isFocused
                                  ? "#F0F0F0"
                                  : isSelected
                                    ? "#34B2C0"
                                    : null,
                                color: isSelected ? "white" : "black",
                                zIndex: 1,
                              }),
                            }}
                            formatOptionLabel={(option, { inputValue }) => {
                              const matches = option.label.match(new RegExp(inputValue, "gi"));
                              return (
                                <div>
                                  {option.label
                                    .split(new RegExp(`(${inputValue})`, "gi"))
                                    .map((part, index) =>
                                      matches && matches.includes(part) ? (
                                        <strong key={index} style={{ color: "#34B2C0" }}>
                                          {part}
                                        </strong>
                                      ) : (
                                        part
                                      ),
                                    )}
                                </div>
                              );
                            }}
                            value={promoCode}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption || null);
                              setCustomValue("promoCode", selectedOption || null);
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  <div className="flex gap-[10px]">
                    {/* {auth()?.role !== "Experience Officer" ? ( */}
                    <div className="flex gap-4 items-center flex-1">
                      <FormControl fullWidth sx={{ border: "none !important" }}>
                        <TextInput
                          label={`Discount ${discountTypeValue === "percentage" ? "%" : "EGP"}`}
                          {...register("discount")}
                        />
                      </FormControl>
                      {/* Discount Type */}
                      <FormControl fullWidth sx={{ border: "none !important" }}>
                        <InputLabel>Discount Type</InputLabel>
                        <Controller
                          control={control}
                          name="discountType"
                          render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <Select
                              sx={{
                                color: "black",
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#EFEFEF",
                                },
                              }}
                              name={name}
                              onBlur={onBlur}
                              onChange={(e, { props }) => {
                                onChange(props.value);
                              }}
                              variant="outlined"
                              labelId="discount label"
                              id="discount-type"
                              value={value}
                              label="discount-type"
                              inputRef={ref}
                            >
                              <MenuItem value={"percentage"}>Percentage</MenuItem>
                              <MenuItem value={"amount"}>Amount</MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </div>
                  </div>
                  {!payWithCredit && (
                    <div className=" mt-2 px-2  ">
                      <div className="flex justify-between items-center">
                        <div className="w-full  my-2 bg-white py-1  text-[18px]  font-bold ">
                          Unpaid
                        </div>
                        <Switch
                          checked={receivable}
                          onChange={(_, checked) => setReceivable(checked)}
                        />
                      </div>
                      {receivable && (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="Expected Due Date"
                            value={recievableDate}
                            onChange={(newValue) => {
                              setRecievableDate(newValue);
                            }}
                            renderInput={(props) => (
                              <TextField style={{ fontSize: "14px" }} {...props} />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    </div>
                  )}
                </>
              )}

              {!payWithCredit && !receivable && !payWithPackage && (
                <div className="mt-4 mb-2">
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel> Payment Method</InputLabel>
                    <Controller
                      control={control}
                      name="paymentMethod"
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <Select
                          sx={{
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange}
                          variant="outlined"
                          labelId="Payment Method-label"
                          id="Payment-Method"
                          value={paymentID}
                          label="Payment Method"
                          inputRef={ref}
                          defaultValue=""
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {paymentMethods?.map((l) => {
                            return (
                              l?.name.toLowerCase() != "receivable" && (
                                <MenuItem key={l?.qboId} value={l?.qboId}>
                                  {l?.name}
                                </MenuItem>
                              )
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500">{errors.paymentMethod?.message}</p>
                </div>
              )}
              {location && user && !payWithPackage && (
                <div className="flex flex-col border-[1px] border-[#79797933] rounded-[10px] mt-4 gap-[5px] p-4 ">
                  <div className="text-[20px] " style={{ fontFamily: "gilroy-bold" }}>
                    Price Calculation
                  </div>
                  <div
                    className="mt-4"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <span className="font-bold "> Subtotal</span>
                    <div className="flex items-center">
                      <span className="text-[#797979]">{prettyNumber(+pricing.subTotal)} EGP</span>
                    </div>
                    <div></div>
                    {pricing.discountAmount && Number(pricing.discountAmount) > 0 ? (
                      <>
                        <div className="font-extrabold">Discount</div>
                        <div className="flex items-center">
                          <span className="text-[#797979]">
                            -{prettyNumber(+pricing.discountAmount)} EGP
                          </span>
                        </div>
                        <div></div>
                      </>
                    ) : null}
                    {pricing.promoCodeAmount && Number(pricing.promoCodeAmount) > 0 ? (
                      <>
                        <div className="font-extrabold">Promo Code</div>
                        <div className="flex items-center">
                          <span className="text-[#797979]">
                            -{prettyNumber(+pricing.promoCodeAmount)} EGP
                          </span>
                        </div>
                        <div></div>
                      </>
                    ) : null}
                    <span className="font-bold "> VAT</span>
                    <div className="flex items-center">
                      <span className="text-[#797979]">{prettyNumber(+pricing.vat)} EGP</span>
                    </div>
                    <div></div>
                  </div>
                  <div className="flex flex-col">
                    <span>Total</span>
                    <span className="text-[42px]" style={{ fontFamily: "gilroy-bold" }}>
                      {prettyNumber(+pricing.totalDiscounted)}
                      EGP
                    </span>
                  </div>
                </div>
              )}

              {!receivable && !payWithPackage && (
                <>
                  <div className="my-4">
                    <TextInput label="Paid Amount" {...register("paidAmount")} />
                    <p className="text-red-500">{errors.paidAmount?.message}</p>
                  </div>
                </>
              )}
              <div className="my-4">
                <TextField
                  style={{ width: "100%" }}
                  variant="outlined"
                  rows={4}
                  multiline
                  label="Notes"
                  {...register("notes")}
                />
                <p className="text-red-500">{errors.notes?.message}</p>
              </div>

              <div className="mt-4 mb-6 flex justify-end gap-10">
                <Button onClick={handleOnClose} secondary={true} invert={true}>
                  Cancel
                </Button>
                <Button type="submit" secondary={true}>
                  Confirm
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className="flex flex-col relative px-10 ">
            <div className="flex gap-4 ">
              <div
                onClick={() => setSteps(0)}
                className="cursor-pointer flex justify-center items-center w-[40px] aspect-square rounded-full bg-[#EFEFEF] hover:[##686868] "
              >
                <img src={backArrow} alt="back" className="w-[8px] h-[15px] object-contain" />
              </div>
              <div
                className="text-[28px] font-bold"
                style={{
                  fontFamily: "gilroy-bold",
                }}
              >
                Security Confirmation
              </div>
            </div>

            <div className="my-4 flex flex-col gap-3">
              <div className="text-[#797979] text-[16px] font-semibold">Client information</div>
              <div
                to={`/community/client/${data?._id}`}
                className="border-[#E4E4E4] border-[1px] rounded-[10px] p-2 flex gap-3"
              >
                <img
                  src={user?.profilePicture}
                  alt={user?.name}
                  className="rounded-[10px] w-[75px] object-cover h-[75px] "
                />
                <div className="flex flex-col justify-center w-full  ">
                  <div className="text-[20px] " style={{ fontFamily: "gilroy-bold" }}>
                    {user?.name}
                  </div>
                  {user?.company && (
                    <div className="text-[#797979]">Member at " {user?.company?.name}"</div>
                  )}
                </div>
              </div>
            </div>

            <div className="text-[16px]">
              I Admin confirm{" "}
              {payWithPackage ? (
                <>
                  the usage of <strong className="font-black text-lg">1</strong> pass by
                </>
              ) : (
                `the payment of "${prettyNumber(+getValues().paidAmount)}" EGP by `
              )}{" "}
              {user?.name} for check in at {location?.name}
            </div>

            <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-10 ">
                <PasswordInput
                  required
                  size="lg"
                  radius={"md"}
                  placeholder="Admin Passowrd"
                  {...register("adminPassword")}
                />
                <p className="text-red-500">{errors.adminPassword?.message}</p>
              </div>

              <div className="flex w-full justify-end  mt-4 gap-5">
                <Button secondary={true} invert={true} onClick={handleOnClose}>
                  Cancel
                </Button>
                <Button loading={submitLoading} type="submit" secondary={true}>
                  Confirm
                </Button>
              </div>
            </form>
          </div>
        )
      ) : (
        <LoadingCircle />
      )}
    </ModalComponent>
  );
};

export default CheckInModal;
